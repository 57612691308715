import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: "2em",
  paddingBottom: "2em",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const Price = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const FeatureList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: 0,
  margin: 0,
  marginBottom: theme.spacing(2),
}));

const FeatureItem = styled("li")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const FeatureText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: theme.typography.body1.fontSize,
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 180,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: "350px",
  margin: "0 auto",
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  transition: "transform 0.3s ease-in-out",
  height: "460px",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

function MealPlans() {
  const { t } = useTranslation();
  const [mealPlans, setMealPlans] = useState([]);

  useEffect(() => {
    const loadMealPlans = async () => {
      const plans = await import(
        `../assets/data/mealPlans.${t("current_language")}.js`
      );
      setMealPlans(plans.default);
    };

    loadMealPlans();
  }, [t]);

  return (
    <StyledContainer align="center">
      <Title>{t("MealPlans.choose_best_plan")}</Title>

      <Grid container spacing={4}>
        {mealPlans.map((plan, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <StyledCard>
              <StyledCardMedia image={plan.image} />
              <CardContent align="left">
                <PlanTitle>{plan.title}</PlanTitle>
                <Price>{plan.price}</Price>
                <FeatureList>
                  {plan.features.map((feature, index) => (
                    <FeatureItem key={index}>
                      <CheckCircleIcon color="primary" />
                      <FeatureText>{feature}</FeatureText>
                    </FeatureItem>
                  ))}
                </FeatureList>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
}

export default MealPlans;
