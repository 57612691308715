import React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  margin: theme.spacing(2),
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 200,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
  flex: "1 0 auto",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100px",
}));

const PriceContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: "center",
}));

const Divider = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  margin: theme.spacing(2, 0),
}));

const RetailPrice = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  color: theme.palette.text.secondary,
}));

const DiscountedPrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: "bold",
  fontSize: "1.5rem",
}));

const SaveBadge = styled(Box)(({ theme }) => ({
  display: "inline-block",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  fontSize: "0.875rem",
  fontWeight: "bold",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.secondary,
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.main,
}));

const MealsDisplay = () => {
  const { t } = useTranslation();
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    const loadMeals = async () => {
      try {
        const mealsData = await import(
          `../assets/data/mealsData.${t("current_language")}.js`
        );
        setMeals(mealsData.default);
      } catch (error) {
        console.error(
          `Could not load meals data for language: ${t("current_language")}. Falling back to English.`,
          error,
        );
      }
    };

    loadMeals();
  }, [t]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <KeyboardArrowRightIcon
        fontSize="large"
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
      />
    ),
    prevArrow: (
      <KeyboardArrowLeftIcon
        fontSize="large"
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container style={{ marginTop: "4em", marginBottom: "2em" }}>
      <Title align="center" sx={{ fontWeight: "bold" }}>
        {t("MealsDisplay.get_discount")}
      </Title>
      <Slider style={{ marginTop: "2em" }} {...settings}>
        {meals.map((meal, index) => (
          <Box key={index} px={1}>
            <StyledCard>
              <StyledCardMedia image={meal.image} title={meal.mealName} />
              <StyledCardContent>
                <ContentContainer>
                  <Typography variant="h5">{meal.mealName}</Typography>
                  <Typography color="textSecondary" marginTop={1}>
                    {meal.description}
                  </Typography>
                </ContentContainer>
                <Divider />
                <PriceContainer>
                  <RetailPrice variant="body2">
                    {t("MealsDisplay.retail_price")}: {meal.retailPrice}
                  </RetailPrice>
                  <DiscountedPrice>
                    {t("MealsDisplay.your_price")}: {meal.discountedPrice}
                  </DiscountedPrice>
                  <SaveBadge>
                    {t("MealsDisplay.save_amount", {
                      amount: meal.savePercent,
                    })}
                  </SaveBadge>
                </PriceContainer>
              </StyledCardContent>
            </StyledCard>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default MealsDisplay;
