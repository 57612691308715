import React, { useState } from "react";
import { Menu, MenuItem, ListItemText, Typography, Box } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const CustomLanguageIcon = styled(LanguageIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "x-large",
  marginRight: 2,
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.fontSize.primary,
  fontWeight: 550,
  fontFamily: theme.typography.fontFamily,
}));

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    handleMenuClose();
  };

  return (
    <div>
      <Box
        onClick={handleMenuOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          mr: 1,
        }}
      >
        <CustomLanguageIcon />
        <CustomTypography>EN | 中</CustomTypography>
      </Box>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleLanguageChange("en")}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange("zh-Hant")}>
          <ListItemText>繁體中文</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange("zh-Hans")}>
          <ListItemText>简体中文</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;
