import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import screenshot1 from "../assets/images/screenshots.jpg";
import appstoreBadge from "../assets/images/appstore-badge.png";
import googleplayBadge from "../assets/images/googleplay-badge.png";
import LogoSvg from "../assets/images/yumgo-full.jpg";

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: "4em",
  paddingBottom: "4em",
}));

const ScreenshotImage = styled("img")(({ theme }) => ({
  width: `min(75%,70vh)`,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
}));

const AppScreenshots = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledContainer alignItems="center">
      <Grid container textAlign="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <ScreenshotImage
            src={screenshot1}
            alt={t("AppScreenshots.alt_text")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign={isSmallScreen ? "center" : "left"}>
            <img
              src={LogoSvg}
              alt="Logo"
              style={{
                width: 145,
                height: 60,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Title>{t("AppScreenshots.title")}</Title>
            <Description gutterBottom>
              {t("AppScreenshots.description")}
            </Description>
            <Box mt={2}>
              <img
                src={appstoreBadge}
                alt={t("AppScreenshots.appstore_badge_alt")}
                style={{ marginRight: "1em", height: "50px" }}
              />
              <img
                src={googleplayBadge}
                alt={t("AppScreenshots.googleplay_badge_alt")}
                style={{ height: "50px" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default AppScreenshots;
