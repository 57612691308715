import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import backgroundImage from "../assets/images/home-background.jpg";
import useTypewriter from "../hooks/useTypewriter"; // Ensure the path is correct
import { useTranslation } from "react-i18next";

const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  height: "50vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  textAlign: "center",
  margin: 0,
  padding: 0,
}));

const Slogan = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.main,
}));

const LogoSlogan = () => {
  const { t } = useTranslation();

  const delightText = useTypewriter(t("good_food"), 100);
  const spendLessText = useTypewriter(t("great_savings"), 100, 1500); // delay "Spend Less" by 1.5 seconds

  return (
    <BackgroundContainer>
      <Slogan variant="h4">{t("discover_yumgo")}</Slogan>
      <Slogan variant="h5" sx={{ fontWeight: "bold" }}>
        {delightText}
      </Slogan>
      <Slogan variant="h5" sx={{ fontWeight: "bold" }}>
        {spendLessText}
      </Slogan>
    </BackgroundContainer>
  );
};

export default LogoSlogan;
